import { create } from "zustand";
import { persist } from "zustand/middleware";



export const useLoadedStore = create((set)=> ({
    isLoaded: false,
    setIsLoaded: (isLoaded) => set({ isLoaded })
}))

const fetchInitialData = async () => {
    try {
        const response = await fetch('https://api.inbodychat.kr/getrules');
        if (!response.ok) {
            throw new Error('Failed to fetch nodes');
        }
        const data = await response.json();
        return data.FAQ_rules || [];
    } catch (error) {
        console.error('Error fetching initial nodes:', error);
        return [];
    }
};

export const useNodeStore = create(
    persist((set, get) => ({
    nodes: [],
    initialNodeIndex: null,
    fullNodeIndex: null,
    
    currentNodes: [],
    setCurrentNodes: (currentNodes) => set({ currentNodes }),

    loadInitialData: async () => {
        const data = await fetchInitialData();
        if (data.nodes && data.initialNodeIndex !== undefined) {
            set({ nodes: data.nodes, initialNodeIndex: data.initialNodeIndex });
        }
    },

    setNodes: (nodes) => set({ nodes }),

    setInitialNodeIndex: (index) => {
        const nodes = get().nodes;
        if (index < 0 ){
            index = 0;
        }else if (index >= nodes.length) {
            index = nodes.length-1;
        }
        set({ initialNodeIndex: index });
    },

    setFullNodeIndex: (index) => {
        set({ fullNodeIndex: index });
    },

    startOption: null,
    setStartOption: (startOption) => set({ startOption }),

    startConnect: (nodeIndex, optionIndex) => {
        get().setStartOption([nodeIndex, optionIndex]);
    },

    appendNode: (newNode) => {
        const { nodes, setNodes } = get();
        setNodes([...nodes, newNode])
    },

    setConnect: (nodeIndex) => {
        const { nodes, startOption, setNodes, setStartOption } = get();

        if (!startOption) return;

        const [startNodeIndex, startOptionIndex] = startOption;

        if (startNodeIndex === nodeIndex) return;

        const updatedNodes = [...nodes];
        const updatedNode = { ...updatedNodes[startNodeIndex] };
        const updatedOptions = [...updatedNode.options];
        updatedOptions[startOptionIndex] = {
            ...updatedOptions[startOptionIndex],
            connectedTo: nodeIndex,
        };
        updatedNode.options = updatedOptions;
        updatedNodes[startNodeIndex] = updatedNode;
        setNodes(updatedNodes);

        setStartOption(null);
    },

    removeConnect: (nodeIndex, optionIndex) => {
        const { nodes, setNodes } = get();
        const updatedNodes = [...nodes];
        const updatedNode = { ...updatedNodes[nodeIndex] };
        const updatedOptions = [...updatedNode.options];
        updatedOptions[optionIndex] = {
            ...updatedOptions[optionIndex],
            connectedTo: null,
        };
        updatedNode.options = updatedOptions;
        updatedNodes[nodeIndex] = updatedNode;
        setNodes(updatedNodes);
    },

    setOptionTitle: (nodeIndex, optionIndex, title) => {
        const { nodes, setNodes } = get();
        const updatedNodes = [...nodes];
        const updatedNode = { ...updatedNodes[nodeIndex] };
        const updatedOptions = [...updatedNode.options];
        updatedOptions[optionIndex] = {
            ...updatedOptions[optionIndex],
            title: title,
        };
        updatedNode.options = updatedOptions;
        updatedNodes[nodeIndex] = updatedNode;
        setNodes(updatedNodes);
    },

    setNodeTitle: (nodeIndex, title) => {
        const { nodes, setNodes } = get();
        const updatedNodes = [...nodes];
        updatedNodes[nodeIndex] = { ...updatedNodes[nodeIndex], title };
        setNodes(updatedNodes);
    },

    setNodeImageTitle: (nodeIndex, imageTitle) => {
        const { nodes, setNodes } = get();
        const updatedNodes = [...nodes];
        updatedNodes[nodeIndex] = { ...updatedNodes[nodeIndex], imageTitle };
        setNodes(updatedNodes);
    },

    setNodeMinimized: (nodeIndex, isMinimized) => {
        const { nodes, setNodes } = get();
        const updatedNodes = [...nodes];
        updatedNodes[nodeIndex] = { ...updatedNodes[nodeIndex], isMinimized };
        setNodes(updatedNodes);
    },

    setNodePosition: (nodeIndex, position) => {
        const { nodes, setNodes } = get();
        const updatedNodes = [...nodes];
        updatedNodes[nodeIndex] = { ...updatedNodes[nodeIndex], ...position };
        setNodes(updatedNodes);
    },

    appendOption: (nodeIndex) => {
        const { nodes, setNodes } = get();
        const updatedNodes = [...nodes];
        const updatedNode = { ...updatedNodes[nodeIndex] };
        const updatedOptions = [...updatedNode.options, { title: "", connectedTo: null }];
        updatedNode.options = updatedOptions;
        updatedNodes[nodeIndex] = updatedNode;
        setNodes(updatedNodes);
    },

    removeOption: (nodeIndex, optionIndex) => {
        const { nodes, setNodes } = get();
        const updatedNodes = [...nodes];
        const updatedNode = { ...updatedNodes[nodeIndex] };
        updatedNode.options = updatedNode.options.filter((_, index) => index !== optionIndex);
        updatedNodes[nodeIndex] = updatedNode;
        setNodes(updatedNodes);
    },

    removeNode: (nodeIndex) => {
        let { nodes, setNodes } = get();

        nodes = nodes.map((node, index) => {
            const updatedOptions = node.options?.map(option => {
                if (option.connectedTo === nodeIndex) {
                    return { ...option, connectedTo: null };
                } else if (option.connectedTo > nodeIndex) {
                    return { ...option, connectedTo: option.connectedTo - 1 };
                }
                return option;
            });
            return { ...node, options: updatedOptions };
        });

        nodes = nodes.filter((_, index) => index !== nodeIndex);
        setNodes(nodes);
    },
}),
{
    name: "node-storage", // 로컬 스토리지에 저장될 키
    getStorage: () => localStorage, // (옵션) 기본값은 localStorage
}));