import React, { useState, useRef, useEffect } from 'react';
import './style.css';
import { useNodeStore, useLoadedStore } from './store.js';

function Rulebot({ setIsEditGraph, setAIMode }) {
    const { nodes, initialNodeIndex, loadInitialData } = useNodeStore((state) => state);
    const { isLoaded, setIsLoaded } = useLoadedStore((state) => state);
    const [currentNodeIndex, setCurrentNodeIndex] = useState(initialNodeIndex);
    const [previousNodeIndices, setPreviousNodeIndices] = useState([]);
    const [chatHistory, setChatHistory] = useState([]);
    const chatHistoryRef = useRef(null);
    const [answerOptions, setAnswerOptions] = useState([]);

    useEffect(() => {
        const initialize = async () => {
            await loadInitialData();
            setCurrentNodeIndex(initialNodeIndex);
        };
        if (!isLoaded) {
            initialize();
            setIsLoaded(true);
        }
    }, [loadInitialData, initialNodeIndex]);

    const UpdateHistory = () => {
        if (nodes[currentNodeIndex]) {
            setAnswerOptions(nodes[currentNodeIndex].options);
            
            const message = {
                role: 'assistant',
                content: nodes[currentNodeIndex].title,
            };

            if(nodes[currentNodeIndex].imageTitle) {
                const image = {
                    role: 'assistant',
                    content: "image: " + nodes[currentNodeIndex].imageTitle,
                };
                setChatHistory((prevHistory) => [...prevHistory, image, message]);
            } else {
                setChatHistory((prevHistory) => [...prevHistory, message]);
            }
        }
    }

    useEffect(() => {
        UpdateHistory()
    }, [currentNodeIndex]);
  
    const resetMessages = () => {
        setChatHistory([]);
        setCurrentNodeIndex(initialNodeIndex);
        UpdateHistory();
        setPreviousNodeIndices([]);
    };
  
    const [isQuestionOpen, setIsQuestionOpen] = useState(true);
  
    const toggleQuestionList = () => {
        setIsQuestionOpen(!isQuestionOpen);
    };

    useEffect(() => {
        if (chatHistoryRef.current) {
            chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
        }
    }, [chatHistory, answerOptions]);

    const handleSendMessage = (e) => {
        e.preventDefault();
        const input = document.querySelector('.chat__input');
        const userMessage = input.value.trim();

        if (userMessage) {
            const message = {
                role: 'user',
                content: userMessage,
            };
            setChatHistory((prevHistory) => [...prevHistory, message]);
            input.value = ''; 
        }
    };

    const handleInputKeyPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            document.getElementById('send').click();
        }
    };
  
    const selectAnswer = (selectedOptionIndex) => {
        const selectedOption = nodes[currentNodeIndex].options[selectedOptionIndex];
        
        const message = {
            role: 'user',
            content: selectedOption.title,
        };
        setChatHistory((prevHistory) => [...prevHistory, message]);

        if (selectedOption.connectedTo !== null) {
            setPreviousNodeIndices((prevIndices) => [...prevIndices, currentNodeIndex]);
            setCurrentNodeIndex(selectedOption.connectedTo);
        } else {
            const endMessage = {
                role: 'assistant',
                content: '대화가 종료되었습니다.',
            };
            setChatHistory((prevHistory) => [...prevHistory, endMessage]);
        }
    };

    const goToPreviousNode = () => {
        if (previousNodeIndices.length > 0) {
            const message = {
                role: 'user',
                content: "이전",
            };
            setChatHistory((prevHistory) => [...prevHistory, message]);
            const lastNodeIndex = previousNodeIndices[previousNodeIndices.length - 1];
            setPreviousNodeIndices((prevIndices) => prevIndices.slice(0, -1));  // 스택에서 마지막 노드를 제거
            setCurrentNodeIndex(lastNodeIndex);
        }
    };

    const goToFirstNode = () => {
        const message = {
            role: 'user',
            content: "처음으로",
        };
        setChatHistory((prevHistory) => [...prevHistory, message]);
        setPreviousNodeIndices((prevIndices) => [...prevIndices, currentNodeIndex]);
        setCurrentNodeIndex(initialNodeIndex);
    };
  
    return (
        <div className="body">
            <header>
                <div className="header-left">
                    <img id="logo" src="inbody-logo.png" alt="inbody logo" />
                    <h1 className="header-title">CS Chatbot</h1>
                </div>
                <div className="header-right"></div>
            </header>
            <div className="btn-wrap">
                <div className="graph-edit-btn" onClick={() => setIsEditGraph(true)}>Flow</div>
            </div>
            <div className="reset">
                <div className="reset-btn" onClick={resetMessages}>대화 초기화</div>
            </div>
            <div className="btn-wrap">
                <div className="mode-btn" onClick={() => setAIMode(true)}>AIMode</div>
            </div>
            <main>
                <div className="chat">
                    <div className="chat__messages" ref={chatHistoryRef}>
                        <div className="chat__message chat__message--sent">
                            <div className="chat__message-bubble">
                                <p>안녕 너는 누구니 ?</p>
                            </div>
                        </div>
                        {/* <div className="chat__message chat__message--received">
                            <div className="chat__message-bubble">
                                <p>반가워요 ! 저는 InBody의 CS 챗봇 Jeremy입니다. <br/>인바디 장비 사용의 불편한 점을 해결하는 방법에 대해서 답변해 드리고 있습니다. <br/>아래의 응답 목록을 클릭하시면 해당 내용에 대한 답변을 제공합니다.</p>
                            </div>
                        </div> */}
                        {chatHistory.map((message, index) => {
                        if (message.role === 'user') {
                            return (
                            <div key={index} className="chat__message chat__message--sent">
                                <div className="chat__message-bubble">
                                <p>{message.content}</p>
                                </div>
                            </div>
                            );
                        } else if (message.role === 'assistant') {
                            if (message.content.startsWith("image: ")) {
                            return (
                                <div key={index} className="chat__message chat__message--received">
                                    <div className="chat__message-bubble">
                                    <img src={message.content.slice(7)}  alt={message.content.slice(7)} />
                                    </div>
                                </div>
                            )
                            }
                            return (
                            <div key={index} className="chat__message chat__message--received">
                                <div className="chat__message-bubble">
                                <p>{message.content}</p>
                                </div>
                            </div>
                            );
                        } else {
                            return null;
                        }
                        })}
                    </div>
                    
                    <div className="recommend_question">
                        <div className="question-arrow-wrap" onClick={toggleQuestionList}>
                            <div className={`question-arrow ${isQuestionOpen ? 'down' : 'up'}`}></div>
                        </div>
                        {isQuestionOpen && (
                            <div className="question-wrap">
                                <div className="question-header" onClick={toggleQuestionList}>
                                    응답을 선택하세요.
                                </div>
                                <div className="question-list">
                                    {answerOptions?.map((option, index) => (
                                        option.title &&
                                        <div key={index} className="question" onClick={() => selectAnswer(index)}>
                                            {option.title}
                                        </div>
                                    ))}
                                    {previousNodeIndices.length > 0 &&
                                        <div className="question" onClick={() => goToPreviousNode()}>
                                            이전
                                        </div>
                                    }
                                    {currentNodeIndex!==initialNodeIndex &&
                                        <div className="question" onClick={() => goToFirstNode()}>
                                            처음으로
                                        </div>
                                    }
                                </div>
                            </div>
                        )}
                    </div>
                    
                    <form className="chat__form" onSubmit={handleSendMessage}>
                        <textarea
                            className="chat__input"
                            id="input"
                            placeholder="메시지를 입력해 주세요."
                            style={{ resize: 'none' }}
                            rows="1"
                            onKeyDown={handleInputKeyPress}
                        ></textarea>
                        <button className="chat__send" id="send" type="submit">
                            전송
                        </button>
                    </form>
                </div>
            </main>
        </div>
    );
}

export default Rulebot;